<!-- =========================================================================================
  File Name: LabsSelector.vue
  Description: eCommerce Shop Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div
      class="rounded-lg justify-between items-center rounded-lg mb-8"
      style="
        background-image: url(https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/global-banner.png);
        box-shadow: rgb(0 0 0 / 10%) 0px 4px 25px 0px;
      "
    >
      <div class="flex justify-between items-center rounded-lg p-4" style="border: 1px solid #181d2a">
        <div class="flex items-center">
          <img
            alt="labs"
            draggable="false"
            src="https://connect-cdn.intellectualpoint.com/assets/images/apps/LabsIcon.png"
            class="rounded hidden sm:block mr-5"
            style="width: 75px"
          />
          <div>
            <h1 class="font-bold">Lab Selector</h1>
            <h5 class="font-light">{{ labs.length }} Total Labs</h5>
          </div>
        </div>

        <div class="flex items-center" v-if="labRunning">
          <div class="text-right mr-5">
            <h1 class="font-bold">Running Lab</h1>
            <h5 class="font-light">{{ labDetails.name }}</h5>
          </div>
          <img alt="labs" draggable="false" :src="labDetails.thumbnail" class="rounded hidden sm:block" style="width: 75px" />
        </div>

        <div v-else class="flex flex-col items-end justify-center" style="min-width: 30rem">
          <vs-input
            v-model="labSearch"
            placeholder="Search For Lab"
            @input="filterLabs"
            class="w-full"
            color="primary"
            icon-pack="feather"
            icon="icon-search"
            icon-no-border
          ></vs-input>
        </div>
      </div>
    </div>

    <div v-if="labsLoaded">
      <div class="labs-grid-view vx-row match-height">
        <div v-for="item in labs" :key="item.id" class="vx-col card-width-sims">
          <lab-card :item="item" :active="labDetails" :auth="labsUserToken" v-on:refresh-labs="labEnded" />
        </div>
      </div>
      <h1 v-if="!labs.length">No Labs Found</h1>
    </div>
    <div class="simple-spinner" v-else>
      <span></span>
    </div>
  </div>
</template>

<script>
import LabCard from './components/LabCard.vue';
import LottieAnimation from '../../../../components/lottie/LottieAnimation';

export default {
  components: {
    LabCard,
    LottieAnimation,
  },
  data() {
    return {
      labs: [],
      labsTotal: [],

      labsUserToken: null,

      labsLoaded: false,

      paginationPageSize: 500,
      currentPage: 1,
      showHiddenLabs: false,

      labErrorCount: 0,
      labRunning: false,
      labInfo: null,
      labDetails: null,

      labSearch: '',
    };
  },
  computed: {
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
  },
  methods: {
    filterLabs() {
      this.labs = this.labsTotal.filter((lab) => {
        return lab.name.toLowerCase().includes(this.labSearch.toLowerCase().trim());
      });
    },
    getRunningLab() {
      if (this.labErrorCount > 3) {
        return this.$vs.notify({
          color: 'danger',
          title: 'Failed to Get Current Lab',
          text: 'Please contact support if this keeps happening',
          icon: 'feather',
          iconPack: 'icon-x-circle',
          position: 'top-right',
        });
      }
      this.$httpLabs
        .post('tenants/connect/get', {
          token: this.labsUserToken,
        })
        .then((response) => {
          this.labInfo = response.data;

          const activityId = this.labInfo.instance.activityId;
          const filter = this.labs.filter((lab) => lab.externalActivityId === activityId);

          if (filter.length) {
            this.labDetails = filter[0];
            this.labRunning = true;

            const index = this.labsTotal.findIndex((obj) => obj.id === this.labDetails.id);
            if (index !== -1) {
              const obj = this.labsTotal.splice(index, 1)[0];
              this.labsTotal.unshift(obj);
            }

            this.labs = this.labsTotal;

            return true;
          }
        })
        .catch((error) => {
          if (error.response.data.type === 'NO_LAB_RUNNING') {
            return true;
          }

          this.labErrorCount++;
          this.getRunningLab();
        })
        .finally(() => {
          this.labsLoaded = true;
        });
    },
    loadLabsData() {
      this.labsLoaded = false;

      if (this.activeCourseInfo.selected === false) {
        this.$vs.notify({
          title: 'No Course Selected',
          text: 'Please select a course before practicing',
          color: 'danger',
          position: 'top-right',
        });

        this.$router.push('/courses');

        return;
      }

      this.$http
        .get(`lab/activity/selector?courseId=${this.activeCourseInfo.id}`)
        .then((response) => {
          if (response.data) {
            if (response.data.length === 0) {
              this.$vs.notify({
                title: 'No Labs In Course',
                text: 'There are no labs in the course you are trying to practice',
                color: 'danger',
                position: 'top-right',
              });
              return this.$router.push('/apps');
            }

            this.labsTotal = response.data.labs;
            this.labs = this.labsTotal;

            this.labsUserToken = response.data.auth;
            localStorage.setItem('labsToken', this.labsUserToken);

            this.getRunningLab();
          }
        })
        .catch((exception) => {
          console.log(exception);
          let error = 'An unknown error occurred while loading labs';
          if (exception.response) {
            error = exception.response.data.message;
          }

          return this.$vs.notify({
            title: 'Failed To Load Course Labs',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
    labEnded() {
      this.labRunning = false;
      this.labInfo = null;
      this.labDetails = null;
      this.loadLabsData();
    },
  },
  beforeMount() {
    this.loadLabsData();
  },
};
</script>

<style lang="scss" scoped>
.html {
  overflow-y: scroll !important;
}

.card-width-sims {
  width: 20%;
}

@media (max-width: 2200px) {
  .card-width-sims {
    width: 25%;
  }
}

@media (max-width: 1800px) {
  .card-width-sims {
    width: 33.333333%;
  }
}

@media (max-width: 1300px) {
  .card-width-sims {
    width: 50%;
  }
}

@media (max-width: 750px) {
  .card-width-sims {
    width: 100%;
  }
}
</style>
