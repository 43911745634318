<!-- =========================================================================================
  File Name: LabCard.vue
  Description: Item Component - Grid VIew

========================================================================================== -->

<template>
  <vx-card
    class="lab-grid-view-item mb-base overflow-hidden w-full no-padding cursor-pointer"
    :class="`${active && active.id === item.id ? 'border-2 border-solid border-primary cursor-pointer' : 'border-none'} ${
      active && active.id !== item.id ? 'cursor-not-allowed' : ''
    }`"
    v-on="$listeners"
  >
    <template slot="no-body">
      <div class="item-img-container flex items-center justify-center" @click="openStarter">
        <img :src="item.thumbnail" :alt="item.name" class="grid-view-img" style="width: 100%; height: 330px; object-fit: cover" />
        <div class="lab-card-overlay flex flex-col justify-between">
          <div class="flex justify-between items-center">
            <img
              src="https://connect-cdn.intellectualpoint.com/assets/images/logos/IntellectualPointIcon.png"
              class="w-8 self-center"
              alt=""
            />
            <div class="flex items-center">
              <feather-icon icon="PlayCircleIcon" svgClasses="w-8 h-8 text-primary" v-if="active && active.id === item.id" />
              <feather-icon
                icon="CheckCircleIcon"
                svgClasses="w-8 h-8 text-success"
                v-if="(!active || (active && active.id !== item.id)) && item.started === true"
              />
            </div>
          </div>
        </div>
        <div class="lab-no-launch flex flex-col justify-center items-center p-4" v-if="active && active.id !== item.id">
          <feather-icon icon="AlertTriangleIcon" svgClasses="w-16 h-16 text-warning mb-4" />
          <h1 class="font-bold mb-1 text-center">Another Lab Is Running</h1>
          <h6 class="font-light text-center">
            Please end <span class="font-bold">{{ active.name }}</span> before starting a new one
          </h6>
        </div>
      </div>

      <vs-popup :active.sync="starterActive" style-content="max-height: 600px" :title="`Practice Lab`">
        <div class="p-3">
          <div class="flex items-center justify-between mb-5">
            <div class="flex items-center">
              <img :alt="item.name" v-if="item.thumbnail" :src="item.thumbnail" width="75" class="rounded-lg mr-4" />
              <div>
                <h1 class="font-bold">{{ item.name }}</h1>
                <div class="flex items-center gap-1" v-if="item.duration">
                  <feather-icon icon="ClockIcon" svgClasses="h-4 w-4" />
                  <p class="font-light">Estimated Time To Complete - {{ item.duration }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-center items-center gap-2">
            <vs-button
              v-if="active && active.id === item.id"
              class="w-full font-bold"
              icon="icon-x-circle"
              icon-pack="feather"
              color="danger"
              @click="endLab"
              >End Lab
            </vs-button>
            <vs-button
              v-if="active && active.id === item.id"
              class="w-full font-bold"
              icon="icon-play"
              icon-pack="feather"
              color="success"
              @click="startLab"
              >Resume Lab
            </vs-button>
          </div>

          <vs-button v-if="!active" class="w-full font-bold mb-1" icon="icon-play" icon-pack="feather" @click="startLab"
            >Start Lab
          </vs-button>

          <div class="mb-4" v-if="item.explanation_video_id">
            <p class="font-semibold mb-1">Explanation Video</p>
            <hls-player v-if="starterActive" :video-id="item.explanation_video_id" :key-id="item.id" player-class="rounded-lg" />
          </div>
        </div>
      </vs-popup>
    </template>
  </vx-card>
</template>

<script>
import HlsPlayer from '../../../../../components/plyr/HlsPlayer';

export default {
  data() {
    return {
      starterActive: false,
    };
  },
  methods: {
    confirmExitLab() {
      this.$vs.loading();
      this.$httpLabs
        .post('tenants/connect/end', {
          token: this.auth,
        })
        .then(() => {
          setTimeout(() => {
            this.$vs.loading.close();
            this.$emit('refresh-labs');
            this.$vs.notify({
              title: 'Lab Ended Successfully',
              text: 'Your lab has been successfully ended',
              color: 'success',
              iconPack: 'feather',
              icon: 'icon-check-circle',
            });
          }, 1000);
        })
        .catch((error) => {
          this.$vs.loading.close();
          if (error.response.data.type === 'LAB_PENDING_PROVISION') {
            this.$vs.notify({
              title: "Couldn't End Lab",
              text: 'Please wait until the lab has been created before ending it',
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-x-circle',
            });
          }
          if (error.response.data.type === 'LAB_UNSAVE') {
            this.getLabData();
          }
          if (error.response.data.type === 'NO_LAB_RUNNING') {
            this.$router.push('/labs/select');
          }
        });
    },
    endLab() {
      this.starterActive = false;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'End Lab',
        text: 'Please confirm you want to end the lab. You will loose all of your progress.',
        accept: this.confirmExitLab,
        acceptText: 'End Lab',
      });
    },
    startLab() {
      this.starterActive = false;
      setTimeout(() => {
        this.$router.push(`/labs/${this.item.id}/play`);
      });
    },
    openStarter() {
      if (this.active && this.item.id !== this.active.id) {
        return this.$vs.notify({
          color: 'danger',
          title: "Can't Launch New Lab",
          text: 'Please end your current lab before starting a new one',
          icon: 'feather',
          iconPack: 'icon-x-circle',
          position: 'top-right',
        });
      }
      this.starterActive = true;
    },
  },
  computed: {
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
  },
  props: {
    auth: {
      type: String,
      required: false,
    },
    item: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    HlsPlayer,
  },
};
</script>

<style lang="scss">
.instructions-show-details {
  img {
    max-width: -webkit-fill-available;
  }
}

.lab-grid-view-item {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }

  .lab-card-overlay {
    position: absolute;
    top: 10px;
    bottom: 0;
    right: 10px;
    left: 12px;
  }

  .lab-no-launch {
    transition: all 0.3s ease;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.6);
  }

  .lab-no-launch:hover {
    opacity: 1;
  }
}
</style>
